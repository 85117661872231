import { request } from '@/util/http'

export const statisticTags = (params) => {
  return request({
    url: '/exp/dashboard/statisticTags',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const cultureRate = (params) => {
  return request({
    url: '/exp/dashboard/cultureRate',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const identifyRate = (params) => {
  return request({
    url: '/exp/dashboard/identifyRate',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const recoverRate = (params) => {
  return request({
    url: '/exp/dashboard/recoverRate',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const drugRate = (params) => {
  return request({
    url: '/exp/dashboard/drugRate',
    method: 'get',
    params: {
      ...params
    }
  })
}
export const onGoingFinished = (queryDate, cancerType) => {
  return request({
    url: '/exp/dashboard/onGoingFinished',
    method: 'get',
    params: {
      queryDate,
      cancerType
    }
  })
}

export const getDefaultDate = () => {
  return request({
    url: '/exp/dashboard/getDefaultDate',
    method: 'get'
  })
}
